import React, {FC, useCallback} from 'react';
import {ReactSVG} from 'react-svg';

import {iconAdd, iconEdit} from 'assets/buttons';
import classNames from 'classnames';
import PFBadge from 'shared/components/PF-Badge';
import {useAppDispatch} from 'store';
import {setClientTags, setIsOpenClientTagsPopup, setSideModal} from 'store/actions';

import './style.scss';

type PropsTypes = {
	clientToken: string;
	clientTags: string[];
	className?: string;
};

const ClientTagsComponent: FC<PropsTypes> = ({clientToken, clientTags, className}) => {
	const dispatch = useAppDispatch();

	const openClientTagsPopup = useCallback(
		(token: string, tags: string[]) => {
			dispatch(setSideModal(false));
			if (tags?.length) dispatch(setClientTags(tags));
			dispatch(setIsOpenClientTagsPopup(token));
		},
		[clientToken, clientTags],
	);

	return (
		<div className={`clientTagsComponent ${className || ''}`}>
			<div className="titleWrapper">
				<div className="title">Tags</div>
				<button
					type="button"
					aria-hidden="true"
					className={classNames('button editTagsButton', {
						displayNone: !clientTags.length,
					})}
					onClick={() => openClientTagsPopup(clientToken, clientTags)}>
					<ReactSVG className="editTagsButton__icon" src={iconEdit} />
				</button>
			</div>
			<div className={classNames('tagsContentWrapper')}>
				<button
					type="button"
					aria-hidden="true"
					className={classNames('button addTagsButton', {
						displayNone: !!clientTags.length,
					})}
					onClick={() => openClientTagsPopup(clientToken, clientTags)}>
					<ReactSVG className="addTagsButton__icon" src={iconAdd} />
					<div className="addTagsButton__text at_pf_2025">Add Tags</div>
				</button>
				<div className="tagsContent">
					{!!clientTags.length &&
						clientTags.map(item => (
							<PFBadge key={item} className="tagBadge noCopy">
								{item}
							</PFBadge>
						))}
				</div>
			</div>
		</div>
	);
};

export default ClientTagsComponent;
