import React, {FC, useEffect, useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {ReactSVG} from 'react-svg';

import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import {DEFAULT_AVATAR} from 'const';
import {findPointOnCircle} from 'utils';

import deleted from '../../../assets/avatar/deleted-item.svg';

import './style.scss';

type PropsTypes = {
	variant?: 'rounded-circle' | 'rounded-square';
	className?: string;
	size?: number;
	src?: string | boolean | null | undefined;
	avatarStatusIcon?: string | undefined | null;
	avatarStatusIconSize?: number | undefined | null;
	alt?: string;
	isDeleted?: boolean;
	isLoading?: boolean;
	defaultAvatar?: string | null | undefined;
};

const PFAvatar: FC<PropsTypes> = ({
	variant = 'rounded-circle',
	className,
	size = 40,
	src,
	avatarStatusIcon = null,
	avatarStatusIconSize = 16,
	alt,
	isDeleted = false,
	isLoading = false,
	defaultAvatar,
}) => {
	const imageSrc = src || defaultAvatar || DEFAULT_AVATAR;
	const [coordinates, setCoordinates] = useState<{x: number; y: number} | null>(null);

	useEffect(() => {
		if (avatarStatusIcon && avatarStatusIconSize) {
			const points = findPointOnCircle(135, size / 2, avatarStatusIconSize / 2);
			setCoordinates(points);
		}
	}, [size]);

	return (
		<div
			className={`pf-avatar__wrapper ppi_pf_2025 ${className || ''}`}
			style={{width: `${size}px`, height: `${size}px`, overflow: 'visible'}}>
			<div
				className={`pf-avatar ${variant}`}
				style={{
					width: size,
					height: size,
				}}>
				<LazyLoadImage
					alt={alt}
					height={size}
					width={size}
					src={isDeleted ? deleted : imageSrc}
					placeholder={
						<img
							alt={alt}
							style={{
								width: size,
								height: size,
							}}
							src={defaultAvatar || DEFAULT_AVATAR}
							className={classNames(`pf-avatar__img ${variant}`)}
						/>
					}
					placeholderSrc={defaultAvatar || DEFAULT_AVATAR}
					effect="blur"
					wrapperClassName="displayFlex"
					className={classNames(`pf-avatar__img ${variant}`)}
					threshold={100}
				/>
				{isLoading && (
					<div className="pf-avatar__loader-wrapper">
						<CircularProgress
							classes={{
								root: 'pf-avatar__loader',
								circle: 'pf-avatar__loader-color',
							}}
						/>
					</div>
				)}
			</div>
			{avatarStatusIcon && coordinates && (
				<div
					className="pf-avatar__wrapper__avatarStatusIcon"
					style={{
						top: `${size / 2 + coordinates.y}px`,
						left: `${size / 2 + coordinates.x}px`,
						width: `${avatarStatusIconSize}px`,
						height: 'auto',
					}}>
					<ReactSVG src={avatarStatusIcon} wrapper="div" />
				</div>
			)}
		</div>
	);
};

export default PFAvatar;
