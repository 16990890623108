import React, {useEffect, useState} from 'react';
import {ReactSVG} from 'react-svg';
import {useNavigate} from 'react-router-dom';
import {setCurrentPageTitle, setOpenDeepLinkPopup} from '../../../store/actions';
import {
	Amplitude,
	getClassNamesWithVariant,
	isCompletedProfile,
	joinName,
	renderLocation,
} from '../../../utils';
import PFAvatar from '../../../shared/components/PF-Avatar';
import PFButton from '../../../shared/components/PF-Button';
import PFLink from '../../../shared/components/PF-Link';
import PORTAL_PATHS from '../../../const/url-paths/portal';
import urlPaths from '../../../api/url-paths';

import iconBack from '../../../assets/icon-arrow-back.svg';
import iconEdit from '../../../assets/buttons/icon-pencil.svg';
import iconLocation from '../../../assets/icon-location.svg';
import iconEmail from '../../../assets/icon-email.svg';
import iconWebsite from '../../../assets/icon-website.svg';
import deeplinkIcon from '../../../assets/icon-deeplink.svg';
import calendarIcon from '../../../assets/icon-calendly.svg';
import {PFCopyright, PFNote, PFTooltip} from '../../../shared/components';
import iconPhone from '../../../assets/icon-phone-without-circle.svg';
import iconCompany from '../../../assets/icon-company.svg';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useHandleCopyCalendarLink} from '../../../shared/hooks/clipboard';
import {AdvisorProfileGeneralInfoBloc, AdvisorProfileSocialInfoBloc} from '../../../components';
import {useAppDispatch, useAppSelector} from '../../../store';
import {myProfileSelectors, selectIsAuth} from '../../../store/selectors';
import './style.scss';

const MyProfile = () => {
	const navigate = useNavigate();
	const handleCopyCalendarLink = useHandleCopyCalendarLink();
	const {firstName, lastName, advisor} = useAppSelector(myProfileSelectors.selectMyProfileData);
	const dispatch = useAppDispatch();
	const fullName = firstName + ' ' + lastName;
	const [isDeepLink, setIsDeepLink] = useState(false);
	const myData = useAppSelector(myProfileSelectors.selectMyProfileData);
	const auth = useAppSelector(selectIsAuth);

	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	useEffect(() => {
		if (myData && !isCompletedProfile(myData?.advisor)) {
			setIsDeepLink(false);
		} else {
			setIsDeepLink(true);
		}
	}, [myData]);

	useEffect(() => {
		dispatch(setCurrentPageTitle(fullName));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const emptyFieldMessage = (
		<div className="pf-myProfile__emptyMessage">
			This section is empty.{' '}
			<PFLink to={PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT}>Edit your profile</PFLink> to
			complete it.
		</div>
	);

	const getWebsiteWithProtocol = website => {
		if (website) {
			return website.startsWith('http://') || website.startsWith('https://')
				? website
				: `https://${website}`;
		}
		return null;
	};

	const status = advisor.isActive
		? {
				label: 'Active',
				value: 'active',
		  }
		: {
				label: 'Inactive',
				value: 'inactive',
		  };
	const goToProfileEdit = () => {
		navigate(PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT);
	};
	const goBack = () => {
		Amplitude.setUserProperty('first_name', 'Hellooooo It Meee');

		navigate('/');
	};

	//wrapped=span is used here because otherwise the default is a div that's causing
	//the arrow and the 'back' text to appear in separate lines.
	const backIcon = <ReactSVG wrapper="span" className="me-2" src={iconBack} />;
	const editIcon = <ReactSVG wrapper="span" className="me-2" src={iconEdit} />;
	const linkIcon = <ReactSVG wrapper="span" className="me-2" src={deeplinkIcon} />;
	const meetingIcon = (
		<ReactSVG wrapper="span" className="me-2 calendarIcon" src={calendarIcon} />
	);
	const companyIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconCompany} />
	);
	const phoneIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconPhone} />
	);
	const locationIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconLocation} />
	);
	const mailIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconEmail} />
	);
	const webSiteIcon = (
		<ReactSVG wrapper="span" className="contact-info__info-icon" src={iconWebsite} />
	);

	return (
		<div className="pf-myProfile">
			<div className={'grid-wrapper'}>
				<div className={'header'}>
					<PFButton
						className="goBack-button"
						prefixIcon={backIcon}
						variant="plain"
						onClick={goBack}
						type={'button'}>
						Back
					</PFButton>
				</div>

				<div className={'contact-info'}>
					<div className="contact-info__avatar-wrapper">
						<PFAvatar
							className="contact-info__avatar"
							src={
								advisor.profileImage &&
								`${urlPaths.BASE_IMAGES_URL}${advisor.profileImage}`
							}
							size={90}
						/>
						<div className="contact-info__name-wrapper">
							<h3 className="contact-info__name my-auto">
								{joinName(firstName, lastName)}
							</h3>
							<span
								className={getClassNamesWithVariant(
									'contact-info__status',
									status.value,
								)}>
								{status.label}
							</span>

							<span className="pl-3 contact-info__certificate">
								{advisor?.financialCertificates?.map(item => item.name).join(', ')}
							</span>
						</div>
					</div>
					{!isDeepLink && (
						<PFNote className={'mb-4'}>
							<div>
								Mobile app users can’t see you in the marketplace until you{' '}
								<PFLink to={PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT}>
									complete your profile.
								</PFLink>
							</div>
						</PFNote>
					)}
					<PFButton
						className="w-auto h-auto contact-info__edit-button emp_pf_2025"
						prefixIcon={editIcon}
						variant="plain"
						onClick={goToProfileEdit}
						type={'button'}>
						Edit My Profile
					</PFButton>
					<div className="contact-info__contact">
						{advisor?.companyName && (
							<p className="contact-info__info companyName">
								{companyIcon}
								{advisor?.companyName}
							</p>
						)}
						{advisor?.location && (
							<p className="contact-info__info">
								{locationIcon}
								{renderLocation(advisor?.location)}
							</p>
						)}
						{(myData?.advisor?.contactEmail || myData?.advisor?.email) && (
							<p className="contact-info__info">
								{mailIcon}
								<PFLink
									href={`mailto:${
										myData?.advisor?.contactEmail || myData?.advisor?.email
									}`}>
									{myData?.advisor?.contactEmail || myData?.advisor?.email}
								</PFLink>
							</p>
						)}
						{(myData?.advisor?.webSite || myData?.advisor?.webSite) && (
							<p className="contact-info__info">
								{webSiteIcon}
								<PFLink
									href={getWebsiteWithProtocol(myData?.advisor?.webSite)}
									target="_blank">
									{myData?.advisor?.webSite || myData?.advisor?.webSite}
								</PFLink>
							</p>
						)}
						{advisor?.phoneNumber && (
							<p className="contact-info__info">
								{phoneIcon}
								{advisor?.phoneNumber}
							</p>
						)}
					</div>
					<div className="contact-info__buttons">
						<PFButton
							prefixIcon={linkIcon}
							className={'deepLink'}
							disabled={!isDeepLink}
							onClick={() =>
								dispatch(
									setOpenDeepLinkPopup({
										advisorToken: advisor.token,
										isMyLink: true,
									}),
								)
							}
							type={'button'}>
							My Profile Link
						</PFButton>
						<PFTooltip
							tooltipBody={
								'Click this button to share your profile link with your clients and invite them to add you to their team.'
							}
							arrow
						/>
						{!!advisor?.calendlyLink && (
							<CopyToClipboard
								text={advisor.calendlyLink}
								onCopy={handleCopyCalendarLink}>
								<PFButton
									prefixIcon={meetingIcon}
									variant="secondary"
									type={'button'}
									onClick={() => false}>
									Calendar Link
								</PFButton>
							</CopyToClipboard>
						)}
					</div>
					{!advisor?.calendlyLink && (
						<PFNote className={'mt-5'}>
							<div>
								<PFLink to={PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT}>
									Add your calendar link
								</PFLink>{' '}
								to easily schedule appointments for your clients by creating
								calendar events.
							</div>
						</PFNote>
					)}
				</div>

				<div className={'general-info'}>
					<AdvisorProfileGeneralInfoBloc
						videoPresentationLink={advisor?.videoPresentationLink}
						description={advisor?.description}
						emptyFieldMessage={emptyFieldMessage}
						advisorSpecializationTypes={advisor?.advisorSpecializationTypes}
						hashtags={advisor?.hashtags}
						auth={auth}
					/>
				</div>

				<div className={'social-info'}>
					<AdvisorProfileSocialInfoBloc
						emptyFieldMessage={emptyFieldMessage}
						linkedin={advisor?.linkedin}
						facebook={advisor?.facebook}
						instagram={advisor?.instagram}
						youtube={advisor?.youtube}
						twitter={advisor?.twitter}
					/>
				</div>
			</div>
			<PFCopyright />
		</div>
	);
};

export default MyProfile;
