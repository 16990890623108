import React, {FC, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import type {GroupBase, OptionsOrGroups} from 'react-select';

import {advisorClientsService} from 'api/services';
import PFAsyncCreatableSelect from 'shared/components/PF-AsyncCreatableSelect';
import PFButton from 'shared/components/PF-Button';
import PFPopup from 'shared/components/PF-Popup';
import {useAppDispatch, useAppSelector} from 'store';
import {setClientsTags, setCloseClientTagsPopup, setUserProfileTags} from 'store/actions';
import {
	selectCustomClientTags,
	selectCustomTagsPopupClientToken,
	selectIsOpenCustomTagsPopup,
} from 'store/selectors';
import {Amplitude, ToastDispatcher} from 'utils';

import './style.scss';

type PropsTypes = {
	className?: string;
};

type OptionType = {
	value: string;
	label: string;
};

const ClientTagsPopup: FC<PropsTypes> = ({className}) => {
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector(selectIsOpenCustomTagsPopup);
	const clientsToken = useAppSelector(selectCustomTagsPopupClientToken);
	const clientsTags = useAppSelector(selectCustomClientTags);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const {formState, handleSubmit, watch, trigger, register, setValue, getValues} = useForm({
		mode: 'onChange',
	});

	const onSubmit = async values => {
		const body = {userToken: clientsToken, tags: values.tags?.map(item => item?.value)};

		try {
			setIsSubmitting(true);
			await advisorClientsService.setCustomClientsTags(body);

			Amplitude.track("Edited_Client's_Tag");

			ToastDispatcher.success('Tags saved.');
			dispatch(setCloseClientTagsPopup());
			dispatch(setClientsTags(body));
			dispatch(setUserProfileTags(body));
		} catch (e) {
			ToastDispatcher.error('Unable to save tags. Please try again.');
		} finally {
			setIsSubmitting(false);
		}
	};

	const loadTagsOptions = async (
		search: string,
		prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
	) => {
		const res = await advisorClientsService.searchAdvisorClientTags({
			searchText: search,
			take: 10,
			skip: prevOptions.length,
		});

		const {count} = res.data;
		const {fullCount} = res.data;
		const options = res.data.body.map(it => {
			return {value: it, label: it};
		});

		return {
			options,
			hasMore: fullCount !== 0 && count > options.length + prevOptions.length,
		};
	};

	const isValidNewOption = (value: string) => {
		const existingTags = getValues('tags') || [];
		const normalizedValue = value.trim().toLowerCase();

		return (
			!!value.trim().length &&
			value.trim().length <= 20 &&
			!existingTags.some(tag => tag.value.toLowerCase() === normalizedValue)
		);
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === 'tags') {
				await trigger('tags');
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [watch, trigger]);

	useEffect(() => {
		if (clientsTags && clientsTags?.length)
			setValue(
				'tags',
				clientsTags.map(item => {
					return {value: item, label: item};
				}),
			);
	}, [clientsTags]);

	useEffect(() => {
		return () => {
			dispatch(setCloseClientTagsPopup());
		};
	}, []);

	return (
		<PFPopup
			isShow={isOpen}
			handleClose={() => dispatch(setCloseClientTagsPopup())}
			isShowFooter={false}
			submitBtnText="Save"
			primaryVariant="primary"
			isCloseButton
			isShowCancelBtn={false}
			title="Edit Client’s Tags"
			onSubmit={() => false}
			className={{
				root: `clientTagsPopup ${className || ''}`,
				paper: `clientTagsPopup__paper`,
			}}>
			<form onSubmit={handleSubmit(onSubmit)} className="clientTagsPopup__form atm_pf_2025">
				<div className="col-12 tags">
					<PFAsyncCreatableSelect
						{...register('tags', {required: false})}
						id="tags"
						isMulti
						closeMenuOnSelect={false}
						loadOptions={loadTagsOptions}
						formatOptionLabel={option => option.label}
						getOptionValue={option => option.value}
						isValidNewOption={isValidNewOption}
						hideSelectedOptions
						defaultOptions
						showAsterisk={false}
						defaultValue={
							clientsTags && clientsTags?.length
								? clientsTags.map(item => {
										return {value: item, label: item};
								  })
								: null
						}
						placeholder="Enter you tag"
						menuPlacement="auto"
						menuPosition="absolute"
						onChange={newValue => {
							setValue('tags', newValue);
						}}
						error={formState.errors.tags?.message && formState.errors.tags?.message}
					/>
				</div>
				<div className="footer">
					<PFButton disabled={!formState.isValid || isSubmitting} type="submit">
						Save
					</PFButton>
					<PFButton
						onClick={() => dispatch(setCloseClientTagsPopup())}
						variant="secondary"
						type="button">
						Cancel
					</PFButton>
				</div>
			</form>
		</PFPopup>
	);
};

export default ClientTagsPopup;
