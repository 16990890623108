import PORTAL_PATHS from './url-paths/portal';
import {AdvisorsRole} from '../types/enums';

export const SIDEBAR_MENUS = [
	{
		path: PORTAL_PATHS.SIDEBAR.CURRENT,
		label: 'Current',
		className: '',
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: PORTAL_PATHS.SIDEBAR.LEADS,
		label: 'Leads',
		className: '',
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: PORTAL_PATHS.SIDEBAR.MY_COMPANY,
		label: 'My Company',
		className: '',
		role: [AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: PORTAL_PATHS.SIDEBAR.MY_NETWORK,
		label: 'My Network',
		className: 'mn_pf_2025',
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: PORTAL_PATHS.SIDEBAR.REFERRED_TO_ME,
		label: 'Referred to Me',
		className: '',
		role: [AdvisorsRole.Advisor, AdvisorsRole.AccountAdministrator, AdvisorsRole.Administrator],
	},
	{
		path: PORTAL_PATHS.SIDEBAR.COMPANIES,
		label: 'Companies',
		className: '',
		role: [AdvisorsRole.SuperAdmin],
	},
	{
		path: PORTAL_PATHS.SIDEBAR.ADVISORS,
		label: 'Advisors',
		className: '',
		role: [AdvisorsRole.SuperAdmin],
	},
	{
		path: PORTAL_PATHS.SIDEBAR.CONSUMERS,
		label: 'Consumers',
		className: '',
		role: [AdvisorsRole.SuperAdmin],
	},
	{
		path: PORTAL_PATHS.SIDEBAR.API_INSTANCES,
		label: 'API Instances',
		className: '',
		role: [AdvisorsRole.SuperAdmin],
	},
	//we needn't this page now
	// {
	//   path: PORTAL_PATHS.SIDEBAR.MEETINGS,
	//   label: "Meetings",
	//   className: "",
	// },
];
