import React, {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ReactSVG} from 'react-svg';
import {InputGroup, Input} from 'rsuite';
import closeIcon from '../../../assets/icon-close.svg';
import search from '../../../assets/icon-search.svg';
import {setShowSearch, setSidebar, setSideModal, setTableSearch} from '../../../store/actions';
import {Amplitude, buildDefaultURLParams, parseUrlQuery, updateURL} from '../../../utils';
import {GetIsSidebarOpen, GetIsSideModalOpen} from '../../../store/selectors/UISetings';
import {useDebounce} from '../../hooks/utility';
import './style.scss';
import classnames from 'classnames';
import {useAppSelector} from '../../../store';
import {selectIsShowSearch} from '../../../store/selectors/table';
import {AdvisorsRole} from '../../../types/enums';

const PFSearch = ({closeSearch}) => {
	const dispatch = useDispatch();
	const isSideModalOpen = GetIsSideModalOpen();
	const isSideBarOpen = GetIsSidebarOpen();
	const selectedTableRow = useSelector(state => state.table.selectedTableRow);
	const findReducerName = window.location.pathname.split('/').reverse()[0]; // reducer name must match pathname
	const reducerName = findReducerName === 'company-profile' ? 'companies' : findReducerName;
	const reducerPropertyName =
		findReducerName === 'company-profile' ? 'companyUsers' : findReducerName;
	const tableData = useSelector(state =>
		state[reducerName] ? state[reducerName][reducerPropertyName] : [],
	);
	const show = useAppSelector(selectIsShowSearch);
	const [searchText, setSearchText] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const inputEl = useRef(null);
	const role = useSelector(state => state.general?.auth?.roles);
	const [selectSearchItem, setSelectSearchItem] = useState(null);
	const urlParams = parseUrlQuery(document.location);
	const {page, itemsCount, predicate, reverse} = buildDefaultURLParams(urlParams);
	const ts = useSelector(state => state.table.search);

	const handleIsActiveRow = (id, previousRowId) => {
		const foundActiveRow = tableData.find(item => item.token === id);

		if (foundActiveRow) {
			if (foundActiveRow.isActive) {
				foundActiveRow.isActive = false;
			} else {
				foundActiveRow.isActive = true;
			}
		}
	};

	useEffect(() => {
		dispatch(setShowSearch(false));
		if (ts === searchText && ts !== '') dispatch(setTableSearch(''));
	}, [closeSearch]);

	const handleToggle = () => {
		dispatch(setShowSearch(!show));
		setSearchText('');
		const searchText = '';
		updateURL(page, itemsCount, predicate, reverse, searchText);
		if (ts === searchText && ts !== '') dispatch(setTableSearch(''));
		if (isSideModalOpen) {
			handleIsActiveRow(selectedTableRow.token);
			dispatch(setSideModal(false));
		}
		if (!isSideBarOpen) dispatch(setSidebar(true));
	};

	useEffect(() => {
		if (inputEl.current && show) {
			setTimeout(() => {
				inputEl.current.focus();
			}, 200); // setTimeout so that "input" has time to open before focusing on it
		}
	}, [show]);

	const onClickSearchInput = () => {
		if (selectSearchItem && isSideModalOpen) {
			handleIsActiveRow(selectSearchItem.token);
		}
		if (isSideModalOpen) {
			if (!selectSearchItem) handleIsActiveRow(selectedTableRow.token);
			dispatch(setSideModal(false));
		}
		if (!isSideBarOpen) dispatch(setSidebar(true));
	};

	const debouncedSearchText = useDebounce(searchText, 500);

	const onChange = text => {
		const searchText = text.replace(/^\s*/g, '');
		setSearchText(searchText);
	};

	useEffect(() => {
		if (ts !== searchText && ts === '') {
			setSearchText('');

			dispatch(setShowSearch(false));
		}
	}, [ts]);

	useEffect(() => {
		if (searchText !== '' && debouncedSearchText) {
			if (searchText.length >= 3) {
				Amplitude.track('Searched');
			}

			setIsSearching(true);
			dispatch(setTableSearch(debouncedSearchText));
		} else if (ts !== searchText && searchText === '') {
			dispatch(setTableSearch(''));
		}
	}, [debouncedSearchText]);

	return (
		<div
			className={`searchPanel ${
				role.includes(AdvisorsRole.SuperAdmin) ? 'superAdmin' : null
			}`}>
			<div className={classnames('collapseAria', {show: show})}>
				<InputGroup inside className={`pf-inputGroup`}>
					<Input
						className="pf-inputGroup__input"
						placeholder="Search..."
						onChange={onChange}
						onClick={onClickSearchInput}
						value={searchText}
						inputRef={inputEl}
						maxLength="85"
						autoFocus
					/>
					<InputGroup.Addon className={`pf-inputGroup__button`} onClick={handleToggle}>
						<ReactSVG src={closeIcon} />
					</InputGroup.Addon>
				</InputGroup>
			</div>
			<button onClick={handleToggle} className={classnames('searchButton', 'sb_pf_2025')} type={'button'}>
				<ReactSVG wrapper="div" className="pf-search-icon" src={search} />
			</button>
		</div>
	);
};

export default PFSearch;
