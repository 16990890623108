import React, {FC, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {todoService} from 'api/services';
import {iconTodo, iconTodoSendTask, iconViewAll} from 'assets';
import {PORTAL_PATHS} from 'const';
import PFPopup from 'shared/components/PF-Popup';
import PFSafeHtmlRenderer from 'shared/components/PF-SafeHtmlRenderer';
import {TodoItemActionType, TodoModalActionType, TodoModalType, TodoType} from 'types';
import {Amplitude, ToastDispatcher, useErrorHelper} from 'utils';

import SetTodoModal from './components/SetTodoModal/SetTodoModal';
import TodoItem from './components/TodoItem';

import './style.scss';

type PropsTypes = {
	showDescription: boolean;
	shouldColorDeadlines?: boolean;
	userToken: string;
	isCompact: boolean;
	todoItems: TodoType[] | string | null;
	updateCallback?: () => void;
	isVisibleSendTaskButton: boolean;
	maxItems?: number | undefined;
};

type TodoDeleteModalType = {
	token: string | null;
	isVisibleModal: boolean;
};

const initialTodoModalState: TodoModalType = {item: null, isVisibleModal: false};

const initialDeleteTodoModalState: TodoDeleteModalType = {token: null, isVisibleModal: false};

const UserProfileTodoBloc: FC<PropsTypes> = ({
	showDescription,
	shouldColorDeadlines = false,
	userToken,
	isCompact,
	todoItems,
	updateCallback,
	isVisibleSendTaskButton,
	maxItems,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const [todoModalInfo, setTodoModalInfo] = useState<TodoModalType>(initialTodoModalState);
	const [todoDeleteModalInfo, setDeleteTodoModalInfo] = useState<TodoDeleteModalType>(
		initialDeleteTodoModalState,
	);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const deleteTodo = async (token: string): Promise<any> => {
		setIsSubmitting(true);
		try {
			await todoService.deleteTodoByToken(token);

			Amplitude.track('Advisor_Deleted_Task', {
				client: token,
			});

			ToastDispatcher.success('Task deleted');
			setDeleteTodoModalInfo(initialDeleteTodoModalState);
			updateCallback?.();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	const getActions = (
		token: string,
		title: string | null,
		text: string | null,
		deadline: string | null,
		isCompletedTask: boolean,
	): TodoItemActionType[] => {
		const actions = [
			{
				name: 'Edit',
				handler: async () => {
					try {
						const TodoModalState: TodoModalType = {
							item: {token, title, text, deadline, action: 'update'},
							isVisibleModal: true,
						};
						await setTodoModalInfo(TodoModalState);
					} catch (e) {
						errorHelper(e);
						throw e;
					}
				},
			},
			{
				name: 'Delete',
				handler: async () => {
					try {
						const TodoDeleteModalState = {
							token,
							isVisibleModal: true,
						};
						await setDeleteTodoModalInfo(TodoDeleteModalState);
					} catch (e) {
						errorHelper(e);
						throw e;
					}
				},
			},
		];
		if (isCompletedTask) {
			actions.shift();
		}
		return actions;
	};

	const onCancelTodoModal = () => {
		setTodoModalInfo(initialTodoModalState);
	};

	const setTodoBody: TodoModalType = {
		item: {token: userToken, title: null, text: null, deadline: null, action: 'create'},
		isVisibleModal: true,
	};

	const getTodoItems = (data: TodoType[]): JSX.Element[] | null => {
		if (data.length !== 0) {
			// Sort the data array based on deadline
			const sortedData = data.sort((a, b) => {
				// Handle cases where the deadline might be '0001-01-01T00:00:00' or null
				const deadlineA =
					a.deadline && a.deadline !== '0001-01-01T00:00:00'
						? new Date(a.deadline).getTime()
						: Infinity;
				const deadlineB =
					b.deadline && b.deadline !== '0001-01-01T00:00:00'
						? new Date(b.deadline).getTime()
						: Infinity;

				return deadlineA - deadlineB;
			});

			const opportunitiesItems = data.map(item => {
				const date = item?.createdAt && new Date(item?.createdAt);

				const deadline = item?.deadline === '0001-01-01T00:00:00' ? null : item?.deadline;

				const actions = getActions(
					item?.token,
					item?.title,
					item?.text,
					deadline,
					item.isCompleted,
				);

				const title = item?.title || item?.text;
				const text = item?.text?.trim() ? (
					<PFSafeHtmlRenderer htmlContent={item.text} className="todoItemText" />
				) : null;

				return (
					<TodoItem
						showDescription={showDescription}
						shouldColorDeadlines={shouldColorDeadlines}
						title={title}
						text={text}
						deadline={deadline}
						date={date}
						icon={iconTodo}
						actions={actions}
						key={item.token}
					/>
				);
			});
			if (maxItems) {
				return opportunitiesItems.slice(0, maxItems);
			}
			return opportunitiesItems;
		}
		return null;
	};

	const goToViewAll = (): void => {
		navigate(`${PORTAL_PATHS.OTHER_PATHS.VIEW_ALL_TODO_DATA}?token=${userToken}`);
	};

	return (
		<div className="todoData">
			{isVisibleSendTaskButton && (
				<button
					className="todoData__sendTaskButton st_pf_2025"
					type="button"
					onClick={() => setTodoModalInfo(setTodoBody)}>
					<ReactSVG
						wrapper="div"
						className="todoData__sendTaskButton__icon"
						src={iconTodoSendTask}
					/>
					<div className="todoData__sendTaskButton__text">Send Task</div>
				</button>
			)}
			{todoItems && Array.isArray(todoItems) && getTodoItems(todoItems)}
			{todoItems && typeof todoItems === 'string' && <div className="empty">{todoItems}</div>}
			{!isCompact && !!maxItems && (
				<div className="todoData__viewAllButton">
					<button onClick={goToViewAll} type="button">
						View All
						<ReactSVG wrapper="span" className="pf-arrowSvg" src={iconViewAll} />
					</button>
				</div>
			)}
			{!isCompact && todoModalInfo.item && (
				<SetTodoModal
					token={todoModalInfo.item.token}
					title={todoModalInfo.item.title}
					text={todoModalInfo.item.text}
					deadline={todoModalInfo.item.deadline}
					action={todoModalInfo.item.action}
					visible={todoModalInfo.isVisibleModal}
					className="todoModal"
					onCancel={onCancelTodoModal}
					updateCallback={updateCallback}
				/>
			)}
			{todoDeleteModalInfo.token && (
				<PFPopup
					isShow={todoDeleteModalInfo.isVisibleModal}
					title="Delete task?"
					submitBtnText="Delete"
					cancelBtnText="Cancel"
					isShowCancelBtn
					isCloseButton
					isShowFooter
					handleClose={() => setDeleteTodoModalInfo(initialDeleteTodoModalState)}
					primaryVariant="alert"
					disabledSubmitBtn={isSubmitting}
					onSubmit={() => {
						if (todoDeleteModalInfo.token) deleteTodo(todoDeleteModalInfo.token);
					}}>
					<div className="deleteTodoDescription">
						The client will be notified that the task has been deleted.
					</div>
				</PFPopup>
			)}
		</div>
	);
};

export default UserProfileTodoBloc;
